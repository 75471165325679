import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'
import type { Images, Video } from '@/types'

export const UniversitiesMap: {
  [key: string]: string;
} = {
  etu: 'СПбГЭТУ ЛЭТИ',
  tyu: 'ТИУ',
  mgimo: 'МГИМО',
  fa: 'Финуниверситет',
  tpu: 'ТПУ',
  inpsycho: 'МИП',
  mipt: 'МФТИ Физтех',
  bfu: 'БФУ',
  hse: 'НИУ ВШЭ',
  dvfu: 'ДВФУ',
  nsu: 'НГУ',
  misis: 'НИТУ МИСИС',
}

export const startScreenData: {
  title: string;
  description?: string;
  link: string;
  videoMobile: {
    light: Video;
    dark: Video;
  };
  videoDesktop: {
    light: Video;
    dark: Video;
  };
} = {
  title: 'Онлайн-курсы<br> от ведущих экспертов',
  description: 'Программирование, дизайн, маркетинг<br> и&nbsp;ещё 35&nbsp;направлений для вашего развития',
  link: '/catalog/',
  videoMobile: {
    light: {
      webm: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-mobile.webm',
      mp4: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-mobile.mp4',
      poster: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-mobile-poster-new.jpg'
    },
    dark: {
      webm: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-mobile-dark.webm',
      mp4: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-mobile-dark.mp4',
      poster: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-mobile-poster-dark.png'
    }
  },
  videoDesktop: {
    light: {
      webm: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-desktop-new@2x.webm',
      mp4: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-desktop-new@2x.mp4',
      poster: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-desktop-poster-new.jpg'
    },
    dark: {
      webm: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-desktop-dark@2x.webm',
      mp4: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-desktop-dark@2x.mp4',
      poster: 'https://cdn.akademica.pro/wbd-front/academica-static/main-page/start-screen/start-screen-video-desktop-poster-dark.png'
    }
  }
}

export const forWhoBlockData: {
  title: string;
  images: Images[];
  link: string;
  items: {
    image?: Images;
    icon?: string;
    title: string;
    description: string;
  }[]
} = {
  title: 'Кому подойдут курсы на&nbsp;платформе',
  images: [
    getUiPictureData({
      sm: [
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/1@1x.avif',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/1@1x.webp',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/1@1x.png',
      ],
    }),
    getUiPictureData({
      sm: [
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/2@1x.avif',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/2@1x.webp',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/2@1x.png',
      ],
    }),
    getUiPictureData({
      sm: [
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/3@1x.avif',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/3@1x.webp',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/3@1x.png',
      ],
    }),
    getUiPictureData({
      sm: [
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/4@1x.avif',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/4@1x.webp',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/4@1x.png',
      ],
    }),
    getUiPictureData({
      sm: [
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/5@1x.avif',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/5@1x.webp',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/5@1x.png',
      ],
    }),
    getUiPictureData({
      sm: [
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/6@1x.avif',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/6@1x.webp',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/6@1x.png',
      ],
    }),
    getUiPictureData({
      sm: [
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/7@1x.avif',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/7@1x.webp',
        'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/7@1x.png',
      ],
    }),
  ],
  link: '/catalog/',
  items: [
    {
      image: getUiPictureData({
        sm: [
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/notepad@1x.avif',
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/notepad@1x.webp',
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/notepad@1x.png',
        ],
      }),
      title: 'Школьникам и&nbsp;студентам',
      description: 'Сможете учиться у&nbsp;профессионалов из&nbsp;авторитетных вузов и&nbsp;крупных компаний. В&nbsp;своём темпе, в&nbsp;любое удобное время',
    },
    {
      image: getUiPictureData({
        sm: [
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/in-search@1x.avif',
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/in-search@1x.webp',
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/in-search@1x.png',
        ],
      }),
      title: 'Людям в&nbsp;поисках профессии',
      description: 'Разработчик, методист или пилот дронов? Вы&nbsp;изучите основы разных профессий и&nbsp;поймёте, что подходит именно вам',
    },
    {
      image: getUiPictureData({
        sm: [
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/tablet@1x.avif',
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/tablet@1x.webp',
          'https://cdn.akademica.pro/wbd-front/academica-static/main-page/for-who-block/tablet@1x.png',
        ],
      }),
      title: 'Тем, кто хочет узнать что-то новое',
      description: 'Найдёте занятие, которое сделает жизнь интереснее,&nbsp;&mdash; у&nbsp;нас десятки направлений: от&nbsp;фотографии до&nbsp;дизайна интерьеров',
    },
  ]
}

export const videoBlockData: Video = {
  webm: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/video-block/akadenika-animatic.webm',
  mp4: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/video-block/akadenika-animatic.mp4',
  poster: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/video-block/akadenika-animatic-poster.png'
}

export const uniTabsData: {
  title: string;
  slug: string;
}[] = [
  {
    title: 'МФТИ',
    slug: 'mipt'
  },
  {
    title: 'НИУ ВШЭ',
    slug: 'hse'
  },
  {
    title: 'МГИМО',
    slug: 'mgimo'
  },
  {
    title: 'НГУ',
    slug: 'nsu'
  },
  {
    title: 'Финуниверситет',
    slug: 'fa'
  },
  {
    title: 'МИСИС',
    slug: 'misis'
  },
  // TODO: скрыли, так как нету курсов еще для ТГУ
  // {
  //   title: 'ТГУ',
  //   slug: 'tsu'
  // },
]

export const directionsTabsData: {
  title: string;
  slug: string;
}[] = [
  {
    title: 'Разработка',
    slug: 'akd-development'
  },
  {
    title: 'Образование и обучение',
    slug: 'akd-education-and-learning'
  },
  {
    title: 'Финансы',
    slug: 'akd-finance'
  },
  {
    title: 'Управление',
    slug: 'akd-management'
  },
  {
    title: 'Бизнес',
    slug: 'akd-business'
  }
]

export const previewsListData = [
  {
    menuTitle: 'Как работа с&nbsp;данными <br>принесёт сверхприбыль',
    video: {
      webm: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/hse-econometrics.webm',
      mp4: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/hse-econometrics.mp4'
    },
    course: {
      title: 'Эконометрика',
      link: 'https://academika.ru/course/hse-hse-econometrics/',
      duration: '30 часов',
      provider: {
        text: 'НИУ ВШЭ',
        images: [
          getUiPictureData({ sm: ['https://cdn.skillbox.pro/passport/companies-logos/gXXpmyNyMABMJF02UYRHz6J6K3EbDqVvrqQ0I8R8.svg'] }),
        ],
      }
    }
  },
  {
    menuTitle: 'Правда ли, что мозг <br>управляет телом',
    video: {
      webm: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/bfu-psychology101.webm',
      mp4: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/bfu-psychology101.mp4'
    },
    course: {
      title: 'Психология',
      link: 'https://academika.ru/course/bfu-psychology101/',
      provider: {
        text: 'БФУ',
        images: [
          getUiPictureData({ sm: ['https://cdn.skillbox.pro/passport/companies-logos/yjNOUkiEzPLzBXpzbRvdCdkbkjzX0toFUTShOvP3.svg'] }),
        ],
      }
    }
  },
  {
    menuTitle: 'Когда пора <br>обратиться к&nbsp;юристу',
    video: {
      webm: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/mipt-startup-legal.webm',
      mp4: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/mipt-startup-legal.mp4'
    },
    course: {
      title: 'Закон стартапа: юридические основы технологического бизнеса',
      link: 'https://academika.ru/course/mipt-startup-legal/',
      duration: '60 часов',
      provider: {
        text: 'МФТИ Физтех',
        images: [
          getUiPictureData({ sm: ['https://cdn.skillbox.pro/passport/companies-logos/f9drn03JjVW548GxsYWJXHTIv5U4Ok3f9ml5bUyd.svg'] }),
        ],
      }
    }
  },
  {
    menuTitle: 'Как создать систему <br>распознавания текста',
    video: {
      webm: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/nsu-data-lingv.webm',
      mp4: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/nsu-data-lingv.mp4'
    },
    course: {
      title: 'Машинное обучение для&nbsp;анализа текстов',
      link: 'https://academika.ru/course/nsu-nsu-data-lingv/',
      duration: '15 часов',
      provider: {
        text: 'НГУ',
        images: [
          getUiPictureData({ sm: ['https://cdn.skillbox.pro/passport/companies-logos/LhUAXFcRfuqYUhLJYg6NIgFh3XcNRzI3i5TARcOa.svg'] }),
        ],
      }
    }
  },
  {
    menuTitle: 'Где найти <br>инвесторов',
    video: {
      webm: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/fa-projectman.webm',
      mp4: 'https://cdn.skillbox.pro/wbd-front/academica-static/main-page/learn/fa-projectman.mp4'
    },
    course: {
      title: 'Проектный менеджмент: базовый курс',
      link: 'https://academika.ru/course/fa-projectman/',
      duration: '50 часов',
      provider: {
        text: 'Финуниверситет',
        images: [
          getUiPictureData({ sm: ['https://cdn.skillbox.pro/passport/companies-logos/cPX4KzeWpH0NMW0D0yeURbtupoe4DYS3lcEZ5pcg.svg'] }),
        ],
      }
    }
  }
]

export const tilesBlockData: {
  images: Images;
  slides: {
    text: string;
    icons: string[];
  }[];
} = {
  images: getUiPictureData({
    sm: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/partnership-page/start-screen-sm@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/partnership-page/start-screen-sm@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/partnership-page/start-screen-sm@1x.png',
    ],
    lg: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/partnership-page/start-screen-lg@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/partnership-page/start-screen-lg@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/partnership-page/start-screen-lg@1x.png',
    ]
  }),
  slides: [
    {
      text: 'Федеральные и&nbsp;национальные исследовательские университеты',
      icons: ['etu', 'tyu', 'mgimo', 'fa', 'tpu', 'inpsycho']
    },
    {
      text: 'Лидеры российских и&nbsp;международных рейтингов (RAEX, QS, ARWU и&nbsp;других)',
      icons: ['mipt', 'bfu', 'hse', 'dvfu', 'nsu', 'misis']
    }
  ]
}
